<template>
  <v-container fluid>
    <v-expansion-panel class="mb-3" inset v-model="open">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
            <span class="pl-2">{{ sideTitleOfSearchPanel }}</span>
          </div>
        </template>
        <v-layout column class="mx-5">
          <v-flex>
            <v-layout row wrap>
              <v-flex xs6>
                <v-icon left style="font-size: 32px; margin-bottom: -53px"
                  >person_pin</v-icon
                >
                <v-text-field
                  v-model="searchParam.tmis_id"
                  style="margin-left: 34px"
                  label="Tmis Id"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row wrap>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  v-model="searchParam.subject_code"
                  :items="getSubjectListData"
                  :item-text="
                    (item) => item.subject_code + ' - ' + item.subject_name
                  "
                  item-value="subject_code"
                  label="Select Subject"
                  menu-props="auto"
                  hide-details
                  prepend-icon="library_books"
                  @change="getCourceBySubject(searchParam.subject_code)"
                >
                  <template v-slot:selection="dataset">
                    <v-chip
                      close
                      @input="clearData()"
                      :selected="dataset.selected"
                      class="chip--select-multi"
                      >{{
                        dataset.item.subject_code +
                          " - " +
                          dataset.item.subject_name
                      }}</v-chip
                    >
                  </template>
                  <!-- <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-tile-content
                        v-text="data.item"
                      ></v-list-tile-content>
                    </template>
                    <template v-else>
                      <v-list-tile-content>
                        <v-list-tile-title
                          v-html="
                            data.item.subject_code +
                            ' - ' +
                            data.item.subject_name
                          "
                        ></v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </template> -->
                </v-autocomplete>
              </v-flex>
              <v-flex cols="6" sm="6" md="3">
                <v-autocomplete
                  v-model="searchParam.paper_code"
                  :items="getCourceListData"
                  :item-text="
                    (item) => item.paper_code + ' - ' + item.paper_name
                  "
                  item-value="paper_code"
                  menu-props="auto"
                  label="Select Course"
                  hide-details
                  prepend-icon="library_books"
                >
                  <template v-slot:selection="dataset">
                    <v-chip
                      close
                      @input="clearData()"
                      :selected="dataset.selected"
                      class="chip--select-multi"
                      >{{
                        dataset.item.paper_code +
                          " - " +
                          dataset.item.paper_name
                      }}</v-chip
                    >
                  </template>
                  <!-- <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-tile-content
                        v-text="data.item"
                      ></v-list-tile-content>
                    </template>
                    <template v-else>
                      <v-list-tile-content>
                        <v-list-tile-title
                          v-html="
                            data.item.paper_code + ' - ' + data.item.paper_name
                          "
                        ></v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </template> -->
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row wrap>
              <v-flex xs6 style="margin-top: 20px">
                <v-menu :close-on-content-click="true">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="From date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 style="margin-top: 20px">
                <v-menu :close-on-content-click="true">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="To date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 sm6 md6 class="my-3">
                <v-select
                  name="is_scan"
                  class="ma-2"
                  v-model="searchParam.is_scan"
                  item-text="name"
                  item-value="value"
                  :items="getCriteria"
                  menu-props="auto"
                  label="Is Scan"
                  prepend-icon="edit"
                  clearable
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 class="my-3">
            <v-layout justify-center row fill-height align-center>
              <v-btn
                outline
                round
                large
                @click="
                  searchData(
                    searchParam.subject_code,
                    searchParam.paper_code,
                    searchParam.tmis_id,
                    date
                  )
                "
              >
                <v-icon left dark>search</v-icon>Search
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-card flat class="my-0">
      <v-layout wrap class="pb-5">
        <v-flex>
          <v-layout row align-center justify-center wrap fill-height>
            <!-- ins_1 start -->

            <v-card
              color="white"
              height="80"
              width="340"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="purple darken-3"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">person_pin</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      {{ getSearchEXData.summary.total_examiner }}
                    </b>
                    <br />
                    <h6 id="titel_en">Total Examiner</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>

            <v-card
              color="white"
              height="80"
              width="340"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="#1b5e20"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">flip</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.1em">
                    <b>
                      {{ getSearchEXData.summary.total_examiner_scan }} ({{
                        Math.ceil(
                          (getSearchEXData.summary.total_examiner_scan /
                            getSearchEXData.summary.total_examiner) *
                            100
                        )
                      }}%)
                    </b>
                    <br />
                    <h6 id="titel_en">Examiners who Scanned</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>

            <!-- ins_1 END -->
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>

    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-badge left overlap>
            <template v-slot:badge> </template>
            <v-card-text class="title">Examiner Monitoring</v-card-text>
          </v-badge>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
      </v-layout>
    </v-card>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getSearchEXData.data.data"
      :pagination.sync="pagination"
      :rows-per-page-items="pagination.rowsPerPageItems"
      :total-items="getSearchEXData.data.total"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <td>{{ props.item.rn }}</td>
        <td class="text-xs-left">
          {{ props.item.subject_code }} - {{ props.item.subject_name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.paper_code }} - {{ props.item.paper_name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.tmis_id }} - {{ props.item.examiner_name }}
        </td>
        <td class="text-xs-left">{{ props.item.mobile }}</td>
        <td class="text-xs-left">{{ props.item.examiner_email }}</td>
        <td class="text-xs-center">{{ props.item.total_scan }}</td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      dataset: "",
      date: "",
      loading: false,
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "rn",
        },
        { text: "Subject code & Name", value: "subject_code" },
        { text: "Paper Code & Name", value: "fat" },
        { text: "Ex TMIS ID & Name", value: "carbs" },
        { text: "Ex Phone", value: "protein" },
        { text: "Ex Email", value: "protein" },
        { text: "Scaned Script", value: "iron" },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 50, // -1 for All",
        totalItems: 200,
        rowsPerPageItems: [50, 100, 150, { text: "All", value: -1 }],
      },
      sl_count: 0,
      searchParam: {
        exam_initiate_id: 1,
        limit: 10, // for all
      },
      subject: { subject_code: [] },
      paper: { paper_code: [] },
      paper_loading: false,
    };
  },

  created() {
    this.getSubjectDataExSet();
    this.searchData();
  },

  computed: {
    getSubjectListData() {
      return this.$store.getters.getSubjectDataexamineer;
    },
    getCourceListData() {
      return this.$store.getters.getCourceData;
    },
    getSearchEXData() {
      return this.$store.getters.getMonitorExaminer;
    },
    getCriteria() {
      return [
        { value: 2, name: "All" },
        { value: 0, name: "Not Scanned" },
        { value: 1, name: "Scanned" },
      ];
    },
  },

  watch: {
    pagination(cv) {
      console.log("gugaga", cv);
      this.searchData();
    },
  },

  methods: {
    getSubjectDataExSet() {
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      console.log("This Is  Exam Code------->", param);
      this.$store.dispatch("fetchSubjectDataforEX", param);
    },
    getCourceBySubject(x) {
      this.subCode = x;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.subject_code = this.subCode;
      console.log("Tanvir! This is cource data!!", param);
      this.$store.dispatch("fetchCourceDataSubjectWiseforEX", param);
    },

    clearData() {
      console.log("Tanvr Called");
      this.searchParam.subject_code = "";
    },

    searchData(sub_code, pep_code, tmis_id, date) {
      let param = {};
      (param.exam_initiate_id = this.getExamInitiateId),
        (param.subject_code = sub_code),
        (param.paper_code = pep_code),
        (param.tmis_id = tmis_id),
        (param.limit = this.pagination.rowsPerPage),
        (param.page = this.pagination.page),
        (param.submited_date = date),
        (param.is_scan = this.searchParam.is_scan),
        console.log("Tanvir!, Search data", param);
      this.loading = true;
      this.$store.dispatch("fetchMonitoringExaminer", param).then((data) => {
        console.log("Tanvir this is  ex paginate: --->", data.data.from);
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
